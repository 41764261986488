.nav {
  height: 40px;
  background: #111;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
  line-height: 40px;
  z-index: 2000;
  position: fixed;
}

.MuiMenu-paper a {
  color: #fff;
  text-decoration: none;
}

.MuiAppBar-root .MuiTypography-h6 {
  font-size: 16px;
  padding-left: 10px;
}

.nav a {
  color: #fff;
}

nav .nav-wrapper {
  padding: 0 5%;
}

.nav .logo:focus {
  outline-width: 1px !important;
}

.nav .logo img {
  border-radius: 50%;
  border: 1.5px solid #73a7f1;
}

nav.nav .nav-wrapper i,
i.material-icons,
.nav .material-icons {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.nav .title {
  display: inline;
  font-size: 14px;
  padding: 0 10px;
}

.dropdown-content {
  background: #000;
  top: 40px !important;
  left: auto !important;
  right: 6%;
  width: 150px !important;
}

.dropdown-content li {
  height: auto;
}
.dropdown-content li > a,
.dropdown-content li > span {
  font-size: 14px;
  color: #fff;
  display: block;
  line-height: 40px;
  padding: 0px 13px;
}

.dropdown-content li > a > i {
  margin-right: 10px;
}

.dropdown-content li:hover,
.dropdown-content li.active {
  background-color: #111;
}

.btn {
  /* background: #7B4A46; */
  background: #2b2b2b;
  margin: 0;
  text-transform: none;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  min-height: 32px;
  padding: 0 20px;
}

.controls .btn:disabled {
  /* background: #7B4A46; */
  background: #111 !important;
  margin: 10px 10px 0 0;
}

.controls-shuffle,
.controls-ready,
.controls-spread {
  display: none;
  position: relative;
  margin-top: 0;
  width: 100%;
  text-align: center;
  z-index: 500;
  padding: 2vh 0;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.25);
}

.btn-ready,
.btn-shuffle {
  width: 80px;
  background: #0f5e86;
}

.controls.initial .controls-shuffle {
  animation: buttonFadeIn 1s ease-in-out 1;
  display: block;
}

.shuffling .controls-shuffle {
  animation: buttonFadeOut 1s ease-in-out 1;
}

.controls.shuffling .controls-ready {
  animation: buttonFadeIn 0.5s ease-in-out 1;
  display: block;
}

.ready .controls-ready {
  animation: buttonFadeOut 0.5s ease-in-out 1;
}

.controls.ready .controls-spread {
  animation: buttonFadeIn 0.5s ease-in-out 1;
  display: block;
}

.done .controls-spread {
  animation: buttonFadeOut 0.5s ease-in-out 1;
  display: block;
  opacity: 0;
}

.done.no-tutorial .controls-spread {
  animation: none;
  display: none;
}

@keyframes buttonFadeIn {
  0% {
    margin-top: -20px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes buttonFadeOut {
  0% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0 !important;
  }
}

/* start */
.walkthrough {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 5000;
  text-align: center;
  padding-top: 45vh;
  width: 100vw;
  height: calc(100vh + 300px);
}

.walkthrough.end,
.walkthrough.next {
  background: transparent;
  text-align: right;
  left: auto;
  right: 20px;
  height: auto;
  width: auto;
}

.download-confirm {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20vh 40px 0 40px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  animation: confirmInit 0.5s linear;
}

.download-confirm.hidden {
  display: none;
}

.download-confirm .download-progress {
  display: none;
}

/* span.downloading,
span.completed {
  display: none;
} */
/*
.download-confirm.downloading .btn span,
.download-confirm.completed .btn span,
.download-confirm.enabled .btn span {
  display: none;
}

.download-confirm.downloading .btn span.downloading,
.download-confirm.completed .btn span.completed,
.download-confirm.enabled .btn span.completed {
  display: block;
} */

.download-confirm.downloading .download-progress,
.download-confirm.completed .download-progress {
  display: block;
}

.determinate {
  width: 100%;
}

nav li {
  position: relative;
}

nav .badge {
  position: absolute;
  background: #1d7997;
  top: 5px;
  right: 5px;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 9px;
  padding: 0;
  min-width: 0;
  color: #ffffff;
  line-height: 14px;
}
@keyframes confirmInit {
  0% {
    transform: translate(0, -20px);
    opacity: 0.8;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.8;
  }
}

.nav .makeStyles-menuButton-3 {
  margin: 0;
  font-size: 20px;
}
