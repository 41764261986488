.images-download {
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}

.images-download img {
  width: 1px;
  height: 1px;
}