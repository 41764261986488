#matrix-rain {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  position: absolute;
  top: 0;
  left: 0;
}

#matrix-lead {
  position: absolute;
  color: #b0ffee;
  top: 0;
  left: 0;
  z-index: 1;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}