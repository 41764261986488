@media print {
  #project-c .board-bg {
    display: none;
  }
  .nav, .choice .overlay {
    display: none;
  }
  img {
    page-break-before: always; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: always; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }
  #project-c {
    width: 1024px !important;
    height: auto !important;
    color: #000;
    background: #fff;
  }
  * {
    color: #000;
    background: transparent;
    box-shadow: none;
  }
  #project-c .absolute-with-scroll,
  #project-c .tarot-cards.spread-ready,
  #project-c .main {
    position: relative;
    height: auto;
  }
  .main .btn {
    display: none;
  }

  .spread-ready.done .tarot-card.selected.hovered {
    background: transparent;
  }

  #project-c .print-hidden {
    display: block;
  }
  #project-c .view-index {
    display: none;
  }
  .info .des {
    padding-bottom: 20px;
  }
  .tarot-cards .card-name {
    color: #000;
  }
  .selected-position {
    display: none;
  }
  .spread-ready .tarot-card .img-container {
    box-shadow: none;
  }
}