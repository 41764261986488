html,
body {
  height: 100%;
  width: 100%;
}

body {
  background: #000;
  font-family: 'Alegreya Sans', 'Roboto', 'Microsoft YaHei', sans-serif;
  font-size: 16px;
  color: #e7e7e7;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#project-c {
  width: 100vw;
  left: 30px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  display: none;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 400;
  font-family: 'Alegreya Sans', 'Microsoft YaHei', sans-serif;
}

.main {
  height: 100%;
  min-height:  100vh;
  overflow-x: hidden;
}

body.fontLoaded {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.font-sans {
  font-family: 'Alegreya Sans', 'Microsoft YaHei', sans-serif;
}

.font-serif {
  font-family: 'Sahitya', serif;
}

p,
label {
  font-family: 'Alegreya Sans', 'Roboto', 'Microsoft YaHei', sans-serif;
  line-height: 1.5em;
}

input {
  border: 0;
  border-bottom: 1px solid #306080;
  padding: 0 8px;
  font-size: 14px;
  height: 31px;
  line-height: 30px;
  background: rgba(10, 34, 62, 0.63);
  color: #ccc;
  box-shadow: 0 2px 20px 5px rgba(0,0,0,0.3);
  margin: 0;
  box-sizing: border-box;
  position: relative;
  top: -2px;
  left: 0px;
}

input:not([type]):focus:not([readonly]),
.share input:focus,
.share input:active {
  background: rgba(0,0,0,0.5);
  box-shadow: 0 2px 20px 5px rgba(0,0,0,0.3);
  border: 0;
  border-bottom: 1px solid;
}


input.question {
  padding: 2px 4px;
  font-size: 16px;
  background: rgba(0,0,0,0.3);
  border-bottom: 1px solid #444;
  text-align: center;
  width: 98%;
  display: block;
  margin: 10px auto;
}


.btn.disabled {
  background: #333 !important;
}

hr {
  border: 0;
  height: 1px;
  background: #999;
  margin: 20px auto;
  width: 55px;
}

a {
  color: #918fb5;
}

.relative {
  position: relative;
}

.MuiButton-root,
.btn,
.btn-prime {
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  min-width: 120px;
  position: relative;
}
.btn-prime a {
  color: #000;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  width: 80px;
  border-radius: 0 15px 15px 0;
}
.btn:hover,
.btn:active,
.btn:focus,
.btn-prime:hover,
.btn-prime:focus,
.btn-prime:active {
  background: rgba(108, 56, 167, 0.52);
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 10px 2px rgb(148 148 148 / 53%);
}
.btn-prime:hover a,
.btn-prime:focus a,
.btn-prime:active a {
  color: #fff;
}

.btn-second {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.btn-second:active,
.btn-second:focus,
.btn-second:hover {
  background: rgba(0, 127, 189, 0.59);
}

.btn-bullet .btn {
  display: inline-block;
  margin: 0;
  height: 35px;
  line-height: 35px;
  font-weight: 400;
  font-size: 1rem;
}

.btn-bullet .btn:focus {
  box-shadow: 0 0 5px 2px rgba(255,255,255,1);
}

.btn-bullet .btn:nth-child(1) {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 15px 0 0 15px;
}
.btn-bullet .btn:nth-child(2) {
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  border-radius: 0 15px 15px 0;
}
.btn-bullet .btn:nth-child(1):hover {
  background: rgba(0, 127, 189, 0.59);
}
.btn-bullet .btn:nth-child(2):hover {
  background: rgba(74, 11, 146, 0.72);
  color: #fff;
}

#project-c .print-hidden {
  display: none;
}


.zh .vertical-lr {
  writing-mode: vertical-lr;
}
.zh .vertical-rl {
  writing-mode: vertical-rl;
}

.zh .upright {
  text-combine-upright: all;
  margin: 0 0 3px 0;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  filter: brightness(70%) blur(0px);
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
}

.bg-small {
  min-width: 40vw;
  min-height: 40vh;
}

.disable-scrolling {
  height: 100%;
  overflow: scroll;
}

@media all and (max-width: 640px) {
  body {
    font-size: 13px;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.MuiButton-root.MuiButton-outlinedSecondary {
  border-radius: 15px;
  border-color: #ffffff;
  color: #ffffff;
  font-size: 1rem;
  text-transform: none;
  padding: 3px 16px;
  min-width: 80px;
}

.MuiButton-root.MuiButton-outlinedSecondary:active,
.MuiButton-root.MuiButton-outlinedSecondary:focus,
.MuiButton-root.MuiButton-outlinedSecondary:hover {
  background: rgba(0, 127, 189, 0.59);
}

.dark .MuiButton-outlined {
  border-color: #ffffff;
  color: #ffffff;
  border-radius: 15px;
  font-weight: initial;
}
.dark .MuiButton-outlined:hover,
.dark .MuiButton-outlined:focus {
  background: rgba(0, 127, 189, 0.59);
}

.modal-center {
  position: absolute;
  top: 37px;
  left: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  background: rgba(0,0,0, 0.9);
  padding: 40px;
  border-radius: 10px;
}