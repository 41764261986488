.history h1 {
  text-align: center;
}
.history .bg {
  filter: brightness(50%) blur(0px);
  top: 0px;
  animation: intro 1s linear;
}
.history .intro {
  height: 100vh;
  display: block;
  text-align: center;
  padding: 60px 0;
  animation: intro 1s linear;
}
.history h2 {
  font-size: 18px;
  margin: 0;
}
h3.history-name {
  font-size: calc(2vw + 2vh + .5vmin);
  margin: 0 0 10px 0;
}
.history .spread {
  margin-bottom: 3vh;
}
.history .date {
  text-align: center;
  padding: 20px;
  line-height: 30px;
  border-radius: 4px 0 0 4px;
  background: rgba(50, 33, 103, 0.412);
  margin-right: -20px;
}
.history .date .day {
  display: block;
  font-size: 60px;
  padding-bottom: 20px;
}
.history .date .month {
  font-size: 22px;
}
.history .time {
  text-align: right;
  color: #858585;
}
.history .spread-container {
  position: relative;
  filter: invert(100) grayscale(100);
  z-index: 0;
  top: -7vh;
  opacity: 0.6;
  pointer-events: none;
  margin: 0 -35px;
}
.history .item {
  transition: opacity 0.4s;
  opacity: 0.8;
}
.history .item.active,
.history .item:hover,
.history .item:active,
.history .item:focus {
  transition: opacity 0.4s;
  opacity: 1;
}
.history .item .surface {
  position: relative;
  padding-top: 30px;
  z-index: 1;
  animation: surfaceEntry 1s linear 1;
}
@keyframes surfaceEntry {
  0% {
    top: -10px;
    opacity: 0.6;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.history .item {
  position: relative;
  display: block;
  padding: 35px;
  background: rgba(0, 0, 0, 0.761);
  line-height: 2.5;
  /* border-bottom: 1px solid #75618fba; */
  border-radius: 0 4px 4px 0;
  margin-bottom: 0;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.63);
  overflow: hidden;
  height: 100vh;
}
.history .item-bg {
  display: block;
  margin: -35px;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: top center;
  opacity: 0.5;
  filter: brightness(90%) blur(1px);
  transition: filter 0.3s;
}
.history .item:hover .item-bg {
  filter: brightness(100%) blur(0px);
}
.history .item .view {
  text-align: center;
  padding: 2vh 0 0 0;
}

.stack .results,
.history .results {
  display: block;
  width: 100%;
  font-size: 12px;
  height: 150px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  align-content: center;
}

.stack .results.single-row,
.history .results.single-row {
  height: 150px;
}

.tarot-text {
  display: block;
  width: 35px;
  margin: 0;
  height: 120px;
}

.history .surface .number {
  margin-left: -10px;
}
.history .number span {
  display: inline-block;
  border: 1px solid #5d5d5d;
  border-radius: 0;
  height: 20px;
  line-height: 17px;
  position: relative;
  padding: 0 4px;
  font-size: 11px;
  top: -2px;
  background: #000000;
  color: #fff;
  font-weight: 900;
  border-radius: 2px;
  margin-right: 4px;
}

@media all and (max-width: 768px) {
  .stack .results,
  .history .results {
    height: 250px;
  }
  .history .item {
    padding: 10px;
  }
  .history .spread-container {
    margin: 0 -10px;
  }
  .history .item-bg {
    margin: -10px;
  }
  .tarot-text {
    width: 26px;
    height: 120px;
    float: left;
    font-size: 12px;
  }
}

.history .sp-line {
  position: relative;
  left: 2px;
}

.card-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.card-bg img {
  height: 100%;
  width: 100%;
  filter: blur(1px) brightness(80%);
  object-fit: cover;
  position: relative;
  top: 0;
  z-index: -1;
}

.history .stats {
  padding: 20px;
  text-align: right;
}

.history .tarot-text h4 {
  font-size: 18px;
}

@keyframes intro {
  0% {
    margin-top: -10px;
    filter: brightness(40%) blur(0px);
  }
  100% {
    margin-top: 0px;
  }
}

.btn-circle {
  outline: none;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  line-height: 40px;
  border-radius: 50%;
  border: 0;
  background: rgba(0,0,0,0.8);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}

.btn-circle:focus,
.btn-circle:active,
.btn-circle:hover {
  background-color: rgba(17, 2, 32, 0.84);
  color: #7971ef;
}
