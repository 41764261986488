.footer {
  display: block;
  position: relative;
  top: auto;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  padding: 0 8px;
  /* background: rgba(255,255,255, 0.9); */
}
.footer, .footer a {
  color: #111;
}
.footer .copyright {
  text-align: center;
  height: 38px;
  line-height: 38px;
}