*:focus {
  outline: 0;
}
.spread-name {
  width: 100%;
  text-align: left;
  padding: 0 20px;
}
.spread-name .question {
  font-size: 26px;
}
.spread-name h1 {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}
.board-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(30%) blur(1px) saturate(50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.done + .board-bg {
  height: calc(100vh + 340px);
}
.tarot-board {
  position: relative;
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  transition: filter 0.3s ease-out;
}
.tarot-cards.stack {
  position: static;
  margin-top: 14vh;
  width: 100%;
  height: 45vw;
  z-index: 0;
}

.tarot-cards.stack.initial {
  animation: entry 1s linear 1;
}

.tarot-cards.stack.initial .tarot-card:last-child {
  box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.7);
}

.shuffling .tarot-card {
  animation: shuffling 1s ease-in-out 1;
}

/* @media all and (max-width: 640px) {
  .shuffling .tarot-card {
    animation: none;
  }
  .tarot-card {
    /* transition: none;
  }
} */

.tarot-cards.spread-ready {
  position: absolute;
  top: 0;
  margin-top: 0;
  padding-top: 40px;
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  z-index: 0;
  animation: entrySpread 1s linear 1;
  margin-bottom: 100px;
}

.tarot-card {
  cursor: pointer;
  display: block;
  font-size: 14px;
  position: absolute;
  top: 0%;
  left: 50vw;
  margin-left: -13.5vw;
  transition: 0.5s all ease;
  transform: rotateX(55deg) rotateY(180deg) rotateZ(0) translate3d(0, 0, 0)
    scale(0.7);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform;
  /* box-shadow: 0px 20px 50px rgb(0, 0, 0); */
}

.tarot-card .img-container {
  position: relative;
  display: block;
  /* max-width: 210px;
  max-height: 400px; */
  max-width: 210px;
  max-height: 348px;
  width: 27vw;
  height: 46vw;
  overflow: hidden;
  border: 0.5px #333 solid;
  border-radius: 8px;
}

.cards-evan .tarot-card .img-container {
  width: 27vw;
  height: 51vw;
  max-width: 210px;
  max-height: 398px;
}

.cards-evan .tarot-card .img-container {
  width: 27vw;
  height: 51vw;
  max-width: 210px;
  max-height: 398px;
}

.cards-eva .tarot-card .img-container {
  width: 22vw;
  height: 45vw;
  max-width: 210px;
  max-height: 427px;
}

@media all and (min-width: 768px) {
  /* .tarot-card .img-container {
    max-width: 210px;
    max-height: 398px;
  } */
  .tarot-card {
    margin-left: -105px;
  }
}

.tarot-card:not(.done) .tarot-card:last-child .img-container {
  box-shadow: 0 10px 20px 50px rgb(0, 0, 0);
}

.ready .tarot-card:last-child {
  box-shadow: none;
}

.tarot-card img {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #222;
  min-width: 100%;
  min-height: 100%;
}

.tarot-card .img-container {
  transform: rotateY(180deg) translate3d(0, 0, 0);
  transition: 0.5s transform ease;
  transform-style: preserve-3d;
  background: #2b2b2b;
  z-index: 1;
}
.done .tarot-card.selected .img-container {
  background: transparent;
}

.tarot-card .back {
  background: #111;
  z-index: 100;
}

.shuffling .tarot-card,
.ready .tarot-card {
  box-shadow: 0 10px 20px 10px rgba(20, 20, 20, 0.3);
}

.shuffled .tarot-card.not-selected:focus,
.shuffled .tarot-card.not-selected:active,
.shuffled .tarot-card.not-selected:hover,
.shuffling .tarot-card.not-selected:hover,
.shuffling .tarot-card.not-selected:hover + .not-selected,
.shuffling .tarot-card.not-selected:hover + .not-selected + .not-selected,
.shuffling
  .tarot-card.not-selected:hover
  + .not-selected
  + .not-selected
  + .not-selected
  + .not-selected,
.shuffling
  .tarot-card.not-selected:hover
  + .not-selected
  + .not-selected
  + .not-selected {
  margin-top: -10px !important;
  z-index: 300 !important;
  animation: hoverShuffle 1s ease-in-out infinite;
  outline: 0;
}

@keyframes hoverShuffle {
  0% {
    margin-top: -10px;
  }
  50% {
    margin-top: -15px;
  }
  100% {
    margin-top: -9px;
  }
}

.ready .tarot-card.not-selected:hover {
  margin-top: -10px !important;
  z-index: 300 !important;
}

.ready .tarot-card.not-selected.viewing {
  transform: rotateX(0deg) scale(0.75) translate3d(0, -46%, 0) !important;
  z-index: 400 !important;
}

.shuffling .tarot-card:hover {
  margin-top: -10px !important;
  z-index: 300 !important;
}

.orientation {
  font-size: 20px;
  padding-bottom: 20px;
}

.tarot-card.rev .img-container .front {
  transform: rotateZ(180deg) !important;
}

@keyframes selecting {
  0% {
    top: -55%;
    left: 65%;
    transform: scale(0.75) !important;
  }
  100% {
    top: 0;
    left: 70%;
  }
}

.done .tarot-card.selected {
  transform: rotateX(0deg) scale(0.5) translate3d(0, 500px, 0) !important;
  left: 50%;
  /* margin-left: 105px !important; */
}
@media all and (max-width: 640px) {
  /* .done .tarot-card.selected {
    margin-left: 43px !important;
  } */
}
.ready .tarot-card.selected,
.shuffling .tarot-card.selected {
  transform: rotateX(0deg) scale(0.5) translate3d(0, 500px, 0) !important;
  left: 70%;
  transition: all;
  animation: selecting 1s ease-out 1;
}
.done .tarot-card.selected {
  left: 50%;
}
.tarot-card.selected:hover {
  z-index: 100;
}

.tarot-card[data-flip="false"] {
  z-index: initial;
  transform: initial;
}

/* spreads */
.spread-ready .tarot-card .img-container {
  box-shadow: 0 10px 50px 20px rgba(0, 0, 0, 0.3);
}

.spread-ready .tarot-card.not-selected {
  display: none;
}

.spread-ready .tarot-card.selected {
  cursor: pointer;
  transform: rotateX(0) scale(0.5) !important;
  z-index: 1000 !important;
  transition: all 0.5s;
}

.tarot-card .info {
  display: none;
}

.spread-ready .tarot-card .info {
  position: relative;
  display: none;
  width: calc(100% - 90px - 27%);
  z-index: 900;
  padding: 0 0 0 40px;
  font-size: 14px;
  line-height: 2rem;
  overflow-y: auto;
  height: 100%;
}

.spread-ready .tarot-card.hovered .info {
  display: block;
}

.info .des {
  max-width: 600px;
  width: 95%;
  padding-bottom: 300px;
}

.spread-position {
  font-size: 18px;
  margin-bottom: 10px;
}

.spread-ready.done .tarot-card.selected.hovered {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: calc(100vh + 300px);
  overflow: hidden;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0 !important;
  transform: rotateY(0) !important;
  z-index: 4000 !important;
  /* filter: blur(0); */
}

/* .has-hovered .spread-ready.done .tarot-card.selected:not(.hovered) {
  filter: blur(3rem);
} */

@keyframes enableScroll {
  0% {
    overflow-y: auto;
  }
  99% {
    overflow-y: auto;
  }
  100% {
    overflow-y: auto;
  }
}

.tarot-cards .card-name {
  display: none;
  text-align: center;
  font-size: 12px;
  color: #fff;
  padding: 22px 0;
  background: transparent;
  position: absolute;
  width: 100%;
  transform: scale(2.5);
}

.tarot-cards.done .tarot-card.viewed .card-name {
  display: block;
}
.tarot-cards.done .tarot-card.hovered .card-name {
  display: none;
}

.tarot-cards.done .tarot-card:not(.viewed) .view-index,
.tarot-cards.ready .tarot-card.selected .view-index,
.tarot-cards.ready .tarot-card.viewing .view-index,
.tarot-card.selected:hover .view-index {
  z-index: 5000;
  background: rgba(0, 0, 0, 0.3);
}
.tarot-card.selected:hover .card-position {
  display: block;
}
.tarot-card .card-position {
  display: none;
  position: relative;
  top: 29vw;
  width: 200%;
  height: auto;
  font-size: 42px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.4);
  left: -50%;
}
.walkthrough.end
  + .tarot-cards.done
  .tarot-card.selected.hovered.card-position {
  display: block;
}
.spread-ready .tarot-card.selected.hovered .content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 6% 6% 0 6%;
  height: 100%;
}

.spread-ready .tarot-card.card-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

@media all and (max-width: 640px) {
  .tarot-cards.stack.initial .tarot-card:nth-child(n + 39) {
    display: none;
  }
  .spread-ready .tarot-card.selected.hovered .content {
    padding: 50px 6% 0 6%;
    overflow-y: auto;
  }
  .spread-ready .tarot-card.selected.hovered {
    top: -84px;
    padding: 60px 0;
    overflow-y: auto;
  }
  @media all and (max-width: 640px) {
    .spread-ready .tarot-card .info {
      width: 100vw;
      padding: 0;
    }
  }
}

.spread-ready.done .tarot-card.selected.viewed .img-container,
.spread-ready.done .tarot-card.selected.hovered .img-container {
  transform: rotateY(0) !important;
  z-index: auto !important;
}

.spread-ready.done .tarot-card.selected .back {
  transform: none !important;
  z-index: 5;
}

.spread-ready.done .tarot-card.selected.viewed .back,
.spread-ready.done .tarot-card.selected.hovered .back {
  transform: rotateY(180deg) !important;
  z-index: 0;
}
.spread-ready.done .tarot-card.selected.viewed .back {
  z-index: -1;
}

@keyframes entry {
  0% {
    top: 180px;
    opacity: 0.8;
  }
  100% {
    top: 300px;
  }
}

@keyframes entrySpread {
  0% {
    margin-top: 20px;
    opacity: 0.8;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes shuffling {
  0% {
    top: -30px;
  }
  99% {
    top: 1px;
  }
  100% {
    top: 0;
  }
}

@keyframes shareEntry {
  0% {
    top: 19vh;
    opacity: 0.7;
  }
  100% {
    top: 20vh;
    opacity: 1;
  }
}

.selected.hovered .view-index,
.view-index {
  display: none;
}
.selected .view-index,
.not-selected .view-index {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 50px;
  font-family: serif;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 68%;
}

@media all and (max-width: 640px) {
  body {
    font-size: 12px;
  }
  .tarot-cards.stack {
    margin-top: 19vh;
  }
  .tarot-cards.spread-ready {
    margin-top: 0px;
  }
  /* .tarot-card .img-container {
    width: 86px;
    height: 147px;
  } */
  .tarot-card.hovered .img-container {
    /* width: 132px;
    height: 230px; */
    margin-bottom: 30px;
  }
  /* .tarot-card {
    margin-left: 43px;
    transition: none;
  } */
  .not-selected .view-index {
    font-size: 32px;
  }

  h2 {
    font-size: 1.8rem;
  }

  .selected .view-index,
  .not-selected .view-index {
    font-size: 36px;
  }
}
.controls {
  position: absolute;
  top: 50vh;
  width: 100%;
  text-align: center;
  z-index: 100;
}

.controls-hover {
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-align: center;
}

.controls-hover .btn {
  margin-left: 10px;
  margin-bottom: 10px;
}

@media all and (max-width: 600px) {
  .controls-hover .btn {
    min-width: 130px;
    padding: 0;
  }
}

.skip {
  text-align: center;
}

.spread-info {
  z-index: 100 !important;
  background: rgba(0, 0, 0, 0.7);
  padding: 3% !important;
  height: calc(100vh + 340px) !important;
  width: 100%;
}
