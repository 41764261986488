.notice-cookies {
  position: fixed;
  z-index: 9999;
  width: 350px;
  left: 0;
  text-align: center;
  padding: 15px 30px;
  background: #fff;
  bottom: 0;
  border-radius: 2px 2px 0 0;
  width: 100%;
  bottom: 0;
  bottom: 0;
  line-height: 1.5rem;
}

.notice-cookies a {
  border-bottom: 1px solid #666;
}
.notice-cookies .close {
  position: relative;
  left: 9px;
  top: 0;
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-weight: 500;
  border: 1px solid #333;
  border-radius: 14px;
  padding: 0;
  height: 30px;
  width: 79px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
}

@media all and (max-width: 460px) {
  .notice-cookies {
    display: flex;
    width: 100%;
    padding: 15px;
    text-align: left;
  }
  .notice-cookies .close {
    top: 6px;
  }
}