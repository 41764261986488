.review {
  height:  100vh;
  overflow: hidden;
}
.history.review .item {
  overflow: auto;
}
.history.review .item-bg {
  position: fixed;
}
.history.review .spread-container {
  pointer-events: all;
  height: calc(100vh);
  filter: none;
  opacity: 1;
}

.review.history h2 {
  font-size: 3.56rem;
}