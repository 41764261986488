.pagination {
  position: fixed;
  opacity: 0.8;
  bottom: 10px;
  right: 20px;
  height: auto;
  width: 40px;
  padding: 0;
  margin: 0;
  color: #ccc;
  z-index: 1000;
}

.pagination:hover {
  opacity: 1;
}

.pagination ul {
  border-right: 1px solid #000;
}
.pagination:hover ul {
  border-right-color: #fff;
}

.pagination li.active {
  background: transparent;
}

.pagination li {
  position: relative;
  display: block;
  height: 4vh;
  line-height: 4vh;
  font-size: 10px;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.5s;
  right: -20px;
}

.pagination li span {
  display: none;
  color: transparent;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  right: calc(50% - 5px);
  top: 40%;
  border: 1.5px solid #2e0a8e;
  z-index: 10;
  text-indent: -20px;
  transition: all 0.4s;
}

.pagination li.active span {
  width: 12px;
  height: 12px;
  right: calc(50% - 6px);
  display: block;
}


.pagination li:hover span {
  background: #8ebcff;
  color: #5ed4ff;
  text-indent: -32px;
}

.pagination li.current span {
  background: #fff;
  color: #fff;
  text-indent: -32px;
}

.pagination .vertical-line {
  position: absolute;
  top: 4vh;
  width: 100%;
  height: 44vh;
  border-right: 1px solid #8d8d8d;
  right: calc(50% + 1px);
  z-index: 0;
}
.pagination li a {
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  line-height: 18px;
  font-size: 10px;
}
.pagination .year-bottom,
.pagination .year-top {
  position: absolute;
  top: -4px;
  right: -12px;
  margin: 0 0 20px;
  padding: 0 5px;
  font-weight: bold;
  border-radius: 0;
  height: 18px;
  line-height: 18px;
  /* border-bottom: 1px solid #666 */
}

.pagination .prev-year {
  position: absolute;
  top: -40px;
  right: -12px;
  margin: 0 0 20px;
  padding: 0 5px;
  font-weight: bold;
  border-radius: 0;
  height: 18px;
  line-height: 18px;
}

.pagination .year-bottom span,
.pagination .year-top span {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 16px;
  left: 14px;
  display: block;
  background: #222;
  border: 1px solid #000;
}

.pagination .year-bottom {
  top: auto;
  bottom: -25px;
}
.pagination .year-bottom span {
  top: -3px
}

.pagination:hover .year-bottom span,
.pagination:hover .year-top span {
  background: #ccc;
}

.prev-year:hover,
.pagination:hover .year-bottom:hover,
.pagination:hover .year-top:hover {
  background: #fff;
  border-radius: 2px;
  color: #000;
  font-weight: bold;
}
