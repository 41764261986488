.early-release {
  width: 80%;
  max-width: 500px;
  padding: 23vh 20px 20px 20px;
  margin: 0 auto;
}

.page-soon {
  display: block;
  margin: 0;
  width: 100%;
  position: relative;
}

.hints {
  color: #a5a5a5;
}

.message {
  position: relative;
  padding: 20px;
  margin: 0 -5px;
  background: #0a031066;
  font-style: italic;
  color: #38d851;
  border-radius: 28px;
  z-index: 10;
}

.so-hidden {
  display: block;
  position: relative;
  color: #222;
  margin-top: 50px;
}
