.spread-sphinx .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(0%, 65%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-50%, 65%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(-50%, 25%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(0%, 25%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(50%, 25%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(50%, 65%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(50%, 108%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(0%, 108%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(-50%, 108%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(9%, -24%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="11"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(9%, 24%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="12"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(-240%, -22%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="13"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(-240%, 22%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="14"] {
  transform: rotateX(0) translate3d(-105%, 4%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="15"] {
  transform: rotateX(0) translate3d(105%, 4%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="16"] {
  transform: rotateX(0) translate3d(-105%, 96%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="17"] {
  transform: rotateX(0) translate3d(-105%, 138%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="18"] {
  transform: rotateX(0) translate3d(105%, 96%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="19"] {
  transform: rotateX(0) translate3d(105%, 138%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="20"] {
  transform: rotateX(0) translate3d(-36%, 177%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="21"] {
  transform: rotateX(0) translate3d(-14%, 177%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="22"] {
  transform: rotateX(0) translate3d(10%, 177%, 0) scale(0.3) !important;
}

.spread-sphinx .tarot-card.selected[data-index="23"] {
  transform: rotateX(0) translate3d(35%, 177%, 0) scale(0.3) !important;
}

