.share-wrapper {
  display: block;
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
}
#share-button {
  position: absolute;
  top: 0;
  right: 1rem;
}
.share {
  background: #000000bf;
  border-radius: 8px;
  box-shadow: 0 0 10px 3px #717c8894;
  width: 80vw;
  height: auto;
  opacity: 1;
  transition: max-height 1.5s ease-in-out;
  animation: shareEntry 0.5s ease-in-out 1;
  position: absolute;
  z-index: 10;
  top: 20vh;
  left: 10vw;
  padding: 5vh 5vw;
  background: rgb(0 0 0 / 75%);
  border-radius: 8px;
  box-shadow: 0 0 10px 3px rgb(113 124 136 / 58%);
}
.share h3 {
  font-size: 4vmin;
  text-align: center;
}
.share textarea {
  border: 0;
  border-bottom: 1px solid #306080;
  padding: 0 8px;
  font-size: 14px;
  height: auto;
  line-height: 30px;
  background: rgba(10, 34, 62, 0.63);
  color: #ccc;
  box-shadow: 0 2px 20px 5px rgba(0,0,0,0.3);
  margin: 0;
  box-sizing: border-box;
  position: relative;
  top: -2px;
  left: 0px;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0.6rem;
}
.share .btn-bullet {
  text-align: right;
}

input:not([type]):focus:not([readonly]),
.share input:focus,
.share input:active {
  border: 0.5px solid #4b4b4b;
  background: #000;
  box-shadow: 0 2px 20px 5px rgba(0,0,0,0.3);
}
