.blur {
  filter: blur(1rem);
}
.spread-select {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  display: block;
  text-align: center;
  background: rgba(0,0,0,0.8);
  padding: 40px 0 0 0;
  z-index: 1000;
}
.spread-select.absolute-with-scroll {
  padding-bottom: 0;
}

.choice {
  width: 100%;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  height: 100vh;
  text-align: center;
  padding-top: 32vh;
}
.choice:nth-child(2) {
  padding-top: 20vh;
}
.choice:hover {
  background:rgba(0, 0, 0, 0.22);
}
.choice .content {
  width: 80% !important;
  max-width: 1440px;
  padding: 40px 0;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}
.choice:hover .bg {
  filter: blur(0px) brightness(70%);
}
.choice:hover .btn {
  opacity: 1;
}
.choice .bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  filter: blur(1px) brightness(50%);
  object-fit: cover;
}
.choice .btn {
  opacity: 1;
}
.choice:hover .overlay {
  opacity: 0.3;
}
.choice .overlay {
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #00000052;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.choice .content h2 {
  font-size: 28px;
  text-shadow: 1px 1px 3px #000;
  position: relative;
  z-index: 9999;
}
.choice .des {
  text-shadow: 1px 1px 3px #000;
  position: relative;
}

.choice a {
  color: #fff;
  display: block;
}

.indicators {
  position: fixed;
  height: 80px;
  top: calc(50vh - 50px);
  right: 10px;
  z-index: 9999;
}
.circle {
  display: block;
  padding: 9px 20px;
}
.circle span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: transparent;
  text-indent: 9999px;
  background: rgba(146, 145, 145, 0.8);
  overflow: hidden;
  display: block;
  transition: background 0.5s;
}
.circle:hover span {
  background: #fff;
}
.circle:active span,
.circle:focus span {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(255,255,255,0.4);
}

@media all and (max-width: 640px) {
  .spread-select {
    padding: 50px 0 0 0;
  }
  .choice {
    padding-top: 15vh;
  }
  .indicators {
    right: 0;
  }
}

.spread-select.show {
  display: block;
}
.spread-select {
  display: none;
}

.spread-select .content {
  position: relative;
  width: 100%;
  animation: entrySelect 1s ease 1;
}

.spread-select.selected {
  animation: hiddenOnSelect 1s ease 1;
  opacity: 0;
  z-index: -100;
}

@keyframes entrySelect {
  0% {
    top: -5px;
    opacity: 0.5;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes hiddenOnSelect {
  0% {
    display: block;
    opacity: 1;
    z-index: 1000;
  }
  99% {
    display: block;
  }
  100% {
    display: none;
    opacity: 0;
    z-index: -100;
  }
}

.spread-select .example {
  color: #999;
}

.spread-select .btn {
  color: #000;
}

.spread-select .btn:focus,
.spread-select .btn:active,
.spread-select .btn:hover {
  background: #2f7294;
}

.btn-right,
.btn-left {
  display: inline-block;
  margin: 0;
}

.spread-select .btn-left {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 15px 0 0 15px;
}
.spread-select .btn-right {
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  box-shadow: none;
  border-radius: 0 15px 15px 0;
}

.spread-select .btn-right:hover {
  background: #26a69a;
  color: #fff;
}

.choices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choices .section-name {
  width: 100%;
}

.choices .content .desc {
  padding: 10px 0 30px 0;
  color: #fefefe;
  position: relative;
  z-index: 9999;
  text-shadow: 1px 1px 5px #000;
}

.tabs {
  background: transparent;
  margin-bottom: 0;
  overflow: hidden;
}

.tabs .tab a:focus,.tabs .tab a:focus.active {
}

.tabs .tab a {
  color: #a9a9a9;
}

.tabs .tab a:hover, .tabs .tab a.active {
  background-color: transparent;
  color: #2db5e3;
}
.tabs .indicator {
  background-color: #21a0ff;
}

.hidden-images {
  height: 1px;
  width: 1px;
  margin-left: -1000px;
  overflow: hidden;
}

.hidden-images img {
  width: 1px;
  height: 1px;
}