.spread-celtic-cross .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(-45%, 60%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(-104%, -27%, 0) scale(0.4) !important;
}

.cards-eva.spread-celtic-cross .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(-125%, -22%, 0) scale(0.4) !important;
}

.cards-evan.spread-celtic-cross .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) rotateZ(-90deg) translate3d(-115%, -24%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(-45%, 118%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(-125%, 60%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(-43%, 1%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(40%, 60%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) rotateY(0deg) rotateZ(55deg) translate3d(234%, 8%, 0)
    scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) rotateZ(55deg) translate3d(180%, -16%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) rotateZ(55deg) translate3d(122%, -39%, 0) scale(0.4) !important;
}

.spread-celtic-cross .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) rotateZ(55deg) translate3d(67%, -62%, 0) scale(0.4) !important;
}

.spread-two-choices .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(-49%, 120%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(0%, 120%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(49%, 120%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(-68%, 68%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(-116%, 50%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-163%, 32%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(67%, 68%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(114%, 50%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(163%, 32%, 0) scale(0.4) !important;
}
.spread-two-choices .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(0%, 10%, 0) scale(0.4) !important;
}

.absolute-with-scroll {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin: 0;
  padding: 40px 0 0 0;
  z-index: 1;
}

@media all and (max-width: 640px) {
  .absolute-with-scroll {
    width: 100%;
    margin-left: 0;
  }
}

.tarot-board.absolute-with-scroll {
  padding: 0;
}

.spread-diamond .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(0%, 94%, 0) scale(0.4) !important;
}

.spread-diamond .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-68%, 47%, 0) scale(0.4) !important;
}

.spread-diamond .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(65%, 47%, 0) scale(0.4) !important;
}

.spread-diamond .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(0%, 0%, 0) scale(0.4) !important;
}

.spread-business-partnership .tarot-card.selected[data-index="1"],
.spread-partnership .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(0%, 56%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="2"],
.spread-partnership .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-87%, 56%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="3"],
.spread-partnership .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(86%, 56%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="4"],
.spread-partnership .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(-43%, 110%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="5"],
.spread-partnership .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(44%, 110%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="6"],
.spread-partnership .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-104%, 165%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="7"],
.spread-partnership .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(-44%, 165%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="8"],
.spread-partnership .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(44%, 165%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="9"],
.spread-partnership .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(103%, 165%, 0) scale(0.4) !important;
}
.spread-business-partnership .tarot-card.selected[data-index="10"],
.spread-partnership .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(0%, 0%, 0) scale(0.4) !important;
}

.spread-cupid .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(-145%, 0%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-145%, 97%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(-102%, 17%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(-102%, 78%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(-59%, 48%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-16%, 48%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(26%, -4%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(27%, 48%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(27%, 100%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(70%, 20%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="11"] {
  transform: rotateX(0) translate3d(70%, 78%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="12"] {
  transform: rotateX(0) translate3d(113%, 48%, 0) scale(0.4) !important;
}
.spread-cupid .tarot-card.selected[data-index="13"] {
  transform: rotateX(0) translate3d(160%, 48%, 0) scale(0.4) !important;
}

.spread-mirror .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(-76%, 54%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(84%, 54%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(-22%, 54%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(137%, 54%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(-129%, 54%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(32%, 54%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(-76%, -3%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(84%, -3%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(-76%, 112%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(84%, 112%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="11"] {
  transform: rotateX(0) translate3d(-76%, 168%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="12"] {
  transform: rotateX(0) translate3d(84%, 168%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="13"] {
  transform: rotateX(0) translate3d(-130%, 168%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="14"] {
  transform: rotateX(0) translate3d(135%, 168%, 0) scale(0.4) !important;
}
.spread-mirror .tarot-card.selected[data-index="15"] {
  transform: rotateX(0) translate3d(0%, 168%, 0) scale(0.4) !important;
}

.spread-career .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(30%, 4%, 0) scale(0.4) !important;
}
.spread-career .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-27%, 4%, 0) scale(0.4) !important;
}
.spread-career .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(0%, 54%, 0) scale(0.4) !important;
}
.spread-career .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(90%, 103%, 0) scale(0.4) !important;
}
.spread-career .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(35%, 103%, 0) scale(0.4) !important;
}
.spread-career .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-27%, 103%, 0) scale(0.4) !important;
}
.spread-career .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(-81%, 103%, 0) scale(0.4) !important;
}

.spread-star .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(34%, 4%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-93%, 94%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(27%, 157%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(72%, 41%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(-68%, 41%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-33%, 157%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(97%, 94%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(1%, -3%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(-118%, 84%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(64%, 149%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="11"] {
  transform: rotateX(0) translate3d(100%, 31%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="12"] {
  transform: rotateX(0) translate3d(-91%, 33%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="13"] {
  transform: rotateX(0) translate3d(-59%, 149%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="14"] {
  transform: rotateX(0) translate3d(123%, 84%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="15"] {
  transform: rotateX(0) translate3d(-26%, -11%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="16"] {
  transform: rotateX(0) translate3d(-145%, 76%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="17"] {
  transform: rotateX(0) translate3d(92%, 141%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="18"] {
  transform: rotateX(0) translate3d(129%, 20%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="19"] {
  transform: rotateX(0) translate3d(-118%, 24%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="20"] {
  transform: rotateX(0) translate3d(-88%, 141%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="21"] {
  transform: rotateX(0) translate3d(152%, 75%, 0) scale(0.4) !important;
}
.spread-star .tarot-card.selected[data-index="22"] {
  transform: rotateX(0) translate3d(-27%, 4%, 0) scale(0.4) !important;
}

.spread-three-choices .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(-49%, 120%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(0%, 120%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(49%, 120%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(-68%, 68%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(-116%, 50%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-163%, 32%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(67%, 68%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="11"] {
  transform: rotateX(0) translate3d(114%, 50%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="12"] {
  transform: rotateX(0) translate3d(163%, 32%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(0%, 68%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(-19%, 35%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(-41%, 0%, 0) scale(0.4) !important;
}
.spread-three-choices .tarot-card.selected[data-index="13"] {
  transform: rotateX(0) translate3d(91%, -6%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(0%, 210%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(0%, 158%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(0%, 106%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(-30%, 54%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(30%, 54%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(-80%, 34%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(80%, 34%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(-130%, 14%, 0) scale(0.4) !important;
}

.spread-loving-cup .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(130%, 14%, 0) scale(0.4) !important;
}
