.spread-zodiac .tarot-card.selected[data-index="1"] {
  transform: rotateX(0) translate3d(-158%, 79%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="2"] {
  transform: rotateX(0) translate3d(-106%, 119%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="3"] {
  transform: rotateX(0) translate3d(-54%, 143%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="4"] {
  transform: rotateX(0) translate3d(0%, 160%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="5"] {
  transform: rotateX(0) translate3d(52%, 143%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="6"] {
  transform: rotateX(0) translate3d(106%, 119%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="7"] {
  transform: rotateX(0) translate3d(158%, 79%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="8"] {
  transform: rotateX(0) translate3d(106%, 40%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="9"] {
  transform: rotateX(0) translate3d(52%, 13%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="10"] {
  transform: rotateX(0) translate3d(0%, -7%, 0) scale(0.4) !important;
}
.spread-zodiac .tarot-card.selected[data-index="11"] {
  transform: rotateX(0) translate3d(-52%, 13%, 0) scale(0.4) !important;
}
.spread-zodiac .tarot-card.selected[data-index="12"] {
  transform: rotateX(0) translate3d(-106%, 40%, 0) scale(0.4) !important;
}

.spread-zodiac .tarot-card.selected[data-index="13"] {
  transform: rotateX(0) translate3d(0%, 80%, 0) scale(0.4) !important;
}