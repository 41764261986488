.stack .results {
  width: 100%;
  height: 100px;
}

.reading {
  margin: 0;
  position: relative;
  perspective: 100vw;
}

.selected-position {
  position: fixed;
  top: 70vh;
  text-align: center;
  /* height: 92px; */
  width: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  padding: 2vh 0;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.selected-position.show-position {
  opacity: 1;
}

.selected-position.hide-position {
  opacity: 0;
}

.reading-notes {
  position: relative;
  padding: 100vh 0 0 5%;
}

.reading-notes img {
  width: 150px;
  height: auto;
}
